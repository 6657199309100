import React from 'react';
import './css/impressum.css';

function Impressum({ language, colorMode }) {
  const translations = {
    de: {
      imprint: {
        h1: 'Impressum und Kontakt',
        h2: 'Impressum',
      },
      contact: {
        h2: 'Kontakt',
        number: 'Telefonnummer',
      },
    },
    en: {
      imprint: {
        h1: 'Imprint and contact',
        h2: 'Imprint',
      },
      contact: {
        h2: 'Contact',
        number: 'Phone number',
      },
    },
  };

  return (
    <div className={`impress ${colorMode}`}>
      <h1>{translations[language].imprint.h1}</h1>
      <h2>{translations[language].imprint.h2}</h2>
      <p>
        Konja Rehm<br />
        Kirchstraße 4<br />
        02739 Kottmar<br />
      </p>

      <h2>{translations[language].contact.h2}</h2>
      <p>
        {translations[language].contact.number} +49 176 61217743<br />
        <span style={{ fontWeight: 'bold' }}>E-Mail: </span>
        <a href="mailto:support@konja-rehm.de" className="email-link">
            support@konja-rehm.de
        </a>

      </p>
    </div>
  );
}

export default Impressum;
