import './css/socials.css';
import React, { useState } from 'react';
import github from '../../Images/github.png';
import insta from '../../Images/insta.png';
import konnichen from '../../Images/konnichen.png';
import webp from '../../Images/webp.png';
import dreipunkte from '../../Images/dreipunkte.png';
import konja from '../../Images//konja.png';



function Socials({language, colorMode}) {

  const [menuVisible, setMenuVisible] = useState(null);  // Zustand für das Menü
  const [menuLink, setMenuLink] = useState("");         // Zustand für den Link, der geteilt werden soll
  const [menuIcon, setMenuIcon] = useState("");         // Zustand für das Icon des Links
  const [menuLinkName, setLinkName] = useState("");
  



  const translations = {
    de: {
      main: {
        header: 'Persönliche Links Übersicht',
        s: 'Teilen',
        c: 'Kopieren',
        m: 'Mehr',
        webp: {
          h3: 'Meine eigene Website'
        },
        konnichen: {
          h3: 'Discord Bot Support'
        },
        insta: {
          h3: 'Mein Instagram'
        },
        github: {
          h3: 'Mein Github'
        }
      }
    },
    en: {
      main: {
        header: 'Personal links overview',
        s: 'Share',
        c: 'Copy',
        m: 'More',
        webp: {
          h3: 'My own webpage'
        },
        konnichen: {
          h3: 'Discord Bot Support'
        },
        insta: {
          h3: 'My Instagram'
        },
        github: {
          h3: 'My Github'
        }
      }
    }

  }; 



  const classes = {
    main: {
      overall: 'App-main',
      ul: 'App-main-ul',
      li: 'App-main-ul-li',
      li_menu_button: 'menu-button'
    },
    menu: {
      overall: 'share-menu',
      box: 'share-menu-box',
      button: 'share-menu-button'
    }
  };




  const toggleMenu = (link, icon, name) => {
    setMenuVisible(link);
    setMenuIcon(icon);
    setMenuLink(link)
    setLinkName(name)
  };

  const hideMenu = () => {
    setMenuVisible(null);
    setMenuLink("");
    setMenuIcon("");
    setLinkName("")
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    alert("Link kopiert!");
  };

  const shareViaEmail = (link) => {
    window.open(`mailto:?subject=Check this out&body=${link}`, '_blank');
  };

  const shareViaMessenger = (link) => {
    window.open(`https://m.me/?text=${link}`, '_blank');
  };

  const shareViaWhatsapp = (link) => {
    window.open(`https://wa.me/?text=${link}`, '_blank');
  };

  const shareViaFacebook = (link) => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`, '_blank');
  };

  const shareViaTwitter = (link) => {
    window.open(`https://twitter.com/intent/tweet?url=${link}`, '_blank');
  };

  const shareViaLinkedIn = (link) => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${link}`, '_blank');
  };

  return (
    <div>

      <main className={classes.main.overall}>
        
        <h1>Konja Rehm
        <br></br>
        <img src={konja} className='exP' alt=""></img>
        </h1>

        <h2 className="App-main-heading">{translations[language].main.header}</h2>
        <ul className={classes.main.ul}>
          {/* Beispielhafte Linkeinträge */}
          <li className={classes.main.li}>
            <button className="App-main-ul-li-button">
              <a href="https://konja-rehm.de" className="App-main-ul-li-link">
                <img src={webp} alt="" className="App-main-ul-li-img" />
                <div className="App-main-ul-li-text">
                  <h3>{translations[language].main.webp.h3}</h3>
                </div>
              </a>
              <div className="App-main-ul-li-menu">
                <button className={classes.main.li_menu_button} onClick={() => toggleMenu('https://konja-rehm.de', webp, translations[language].main.webp.h3)}>
                  <img src={dreipunkte} alt="Menü öffnen" />
                </button>
              </div>
            </button>
          </li>

          {/* Weitere Links */}
          <li className={classes.main.li}>
            <button className="App-main-ul-li-button">
              <a href="https://discord.gg/S6cSZwdaZg" className="App-main-ul-li-link">
                <img src={konnichen} alt="" className="App-main-ul-li-img" />
                <div className="App-main-ul-li-text">
                  <h3>{translations[language].main.konnichen.h3}</h3>
                </div>
              </a>
              <div className="App-main-ul-li-menu">
                <button className={classes.main.li_menu_button} onClick={() => toggleMenu('https://discord.gg/S6cSZwdaZg', konnichen, translations[language].main.konnichen.h3)}>
                  <img src={dreipunkte} alt='Du bist alt'></img>
                </button>
              </div>
            </button>
          </li>

          <li className={classes.main.li}>
            <button className="App-main-ul-li-button">
              <a href="https://github.com" className="App-main-ul-li-link">
                <img src={github} alt="" className="App-main-ul-li-img" />
                <div className="App-main-ul-li-text">
                  <h3>{translations[language].main.github.h3}</h3>
                </div>
              </a>
              <div className="App-main-ul-li-menu">
                <button className={classes.main.li_menu_button} onClick={() => toggleMenu('https://github.com', github, translations[language].main.github.h3)}>
                  <img src={dreipunkte} alt="Menu" />
                </button>
              </div>
            </button>
          </li>

          <li className={classes.main.li}>
            <button className="App-main-ul-li-button">
              <a href="https://www.instagram.com/konja_rehm089/" className="App-main-ul-li-link">
                <img src={insta} alt="" className="App-main-ul-li-img" />
                <div className="App-main-ul-li-text">
                  <h3>{translations[language].main.insta.h3}</h3>
                </div>
              </a>
              <div className="App-main-ul-li-menu">
                <button className={classes.main.li_menu_button} onClick={() => toggleMenu('https://www.instagram.com/konja_rehm089/', insta, translations[language].main.insta.h3)}>
                  <img src={dreipunkte} alt='Du bist alt'></img>
                </button>
              </div>

            </button>
          </li>

        </ul>
      </main>


      {menuVisible && (
        <div className={classes.menu.overall}>
          <div className={classes.menu.box}>
          <h5>{translations[language].main.s}</h5>
          <a href={menuLink}>
            <div className="link-info">
              <div className='name'>
              <span>{menuLinkName}</span>
              </div>
              <img src={menuIcon} alt="Icon" className='menuImage'/>
              <div className='link'>
        
                <span>{menuLink}</span>
              </div>
            </div>
          </a>

            <div className="share-box">
              <button className={classes.menu.button} onClick={() => shareViaEmail(menuLink)}>
                Email
              </button>
              <button className={classes.menu.button} onClick={() => shareViaFacebook(menuLink)}>
                FB
              </button>
              <button className={classes.menu.button} onClick={() => shareViaTwitter(menuLink)}>
                TW
              </button>
              <button className={classes.menu.button} onClick={() => shareViaLinkedIn(menuLink)}>
                IN
              </button>
              <button className={classes.menu.button} onClick={() => shareViaWhatsapp(menuLink)}>
                WA
              </button>
              <button className={classes.menu.button} onClick={() => shareViaMessenger(menuLink)}>
                MS
              </button>
              <button className={classes.menu.button} onClick={() => copyLink(menuLink)}>
                {translations[language].main.c}
              </button>
            </div>

            <button className='close' onClick={hideMenu}>Schließen</button>
          </div>
        </div>
      )}

    </div>
  );
}

export default Socials;
