import React from 'react';
import './css/biblepage.css';
import konnichen from '../../Images/konnichen_christmas.png';
import fun from '../../Images/ph1.png';
import moderation from '../../Images/ph2.png';


function BiblePage({language, colorMode}) {

    const translations = {
        de: {
            h1: 'Andachten gestalten - komfortabel und schnell',
            description: 'Eine Website, erstellt zum schnellen feiern von Andachten.',
            functions: {
                title: 'Funktionen',
                f1: 'Schnelle Erstellung von Andachten',
                f2: 'Abspeichern für später',
                f3: 'Zusammen Andachten halten'
            }, 
            visit: 'Zur Website'
        },
        en: {
            h1: 'Design devotions - conveniently and quickly',
            description: 'A website created for quick devotional celebrations.',
            functions: {
                title: 'Functions',
                f1: 'Quick creation of devotions',
                f2: 'Save for later',
                f3: 'Hold devotions together'
            },
            visit: 'To the webpage'
        }
    }

    return (
        <div className='bible-div'>
            <h1 className='big_header'>{translations[language].h1}</h1>

            <p className='description'>{translations[language].description}</p>
            
            <h2 className='function_header'>
                {translations[language].functions.title}
            </h2>
            <ul className='function_list'>
                <li className='left_function'>
                    <img src={konnichen} alt=''></img>
                    <p>{translations[language].functions.f1}</p>
                </li>
                <li className='right_function'>
                    <img src={fun} alt=''></img>
                    <p>{translations[language].functions.f2}</p>


                </li>
                <li className='left_function'>
                    <img src={moderation} alt=''></img>
                    <p>{translations[language].functions.f3}</p>

                </li>
            </ul>

            <button className='visit_button'>
                <a href='https://andachten-gestalten.de'>
                    {translations[language].visit}
                </a>
            </button>
        </div>
    )
}

export default BiblePage;