import React, { useState } from 'react';
import './css/konnichen.css';
import konnichen from '../../Images/konnichen_christmas.png';
import fun from '../../Images/ph1.png';
import moderation from '../../Images/ph2.png';

function Konnichen({language, colorMode}) {
    const [openFunctions, setOpenFunctions] = useState([0, 1, 2]);

    const toggleFunction = (index) => {
        setOpenFunctions(prev => 
            prev.includes(index) 
                ? prev.filter(i => i !== index) 
                : [...prev, index]
        );
    };

    const translations = {
        de: {
            h1: 'Konnichen - Der Discord Bot',
            description: 'Ein Discord Bot, welcher dir in der Adventszeit Spaß und Freude auf den Discord Server bringt, aber auch bei der Instandhaltung von Recht und Ordnung behilflich sein kann.',
            functions: {
                title: 'Funktionen',
                f1: {
                    title: 'Kekssammelsystem',
                    description: 'Sammle virtuelle Kekse durch Aktivität auf dem Server und tausche sie gegen Belohnungen ein.'
                },
                f2: {
                    title: 'Moderation',
                    description: 'Hilft bei der Verwaltung des Servers mit Befehlen für Warnungen, Kicks und Bans.'
                },
                f3: {
                    title: 'Spaßfunktionen',
                    description: 'Verschiedene Befehle für Spiele, Witze und andere unterhaltsame Aktivitäten.'
                }
            }, 
            invite: 'Bot einladen'
        },
        en: {
            h1: 'Konnichen - The Discord Bot',
            description: 'A Discord Bot, made to bring fun and joy to your discord in the christmas time, but also to hold law and order.',
            functions: {
                title: 'Functions',
                f1: {
                    title: 'Cookie collecting',
                    description: 'Collect virtual cookies through server activity and exchange them for rewards.'
                },
                f2: {
                    title: 'Moderation',
                    description: 'Helps manage the server with commands for warnings, kicks, and bans.'
                },
                f3: {
                    title: 'Funny Commands',
                    description: 'Various commands for games, jokes, and other entertaining activities.'
                }
            },
            invite: 'Invite Bot'
        }
    }

    return (
        <div className='konnichen-div'>
            <h1 className='big_header'>{translations[language].h1}</h1>
            <p className='description'>{translations[language].description}</p>
            
            <h2 className='function_header'>{translations[language].functions.title}</h2>
            <ul className='function_list'>
                {['f1', 'f2', 'f3'].map((func, index) => (
                    <li key={index} className={`function-item ${index % 2 === 0 ? 'left_function' : 'left_function'} ${openFunctions.includes(index) ? 'open' : 'closed'}`}>
                        <div className="function-box">
                            <div onClick={() => toggleFunction(index)} className="function-toggle">
                                {openFunctions.includes(index) ? '▼' : '▶'}
                                <h3>{translations[language].functions[func].title}</h3>
                            </div>

                            {openFunctions.includes(index) && (
                                <div className="function-content">
                                    <img src={[konnichen, fun, moderation][index]} alt='' />
                                    <p className="function-description">{translations[language].functions[func].description}</p>
                                </div>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
            <button className='invite_button'>
                <a href='#'>{translations[language].invite}</a>
            </button>
        </div>
    )
}

export default Konnichen;