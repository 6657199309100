import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import english from './Images/english.png';
import german from './Images/german.png';
import Impressum from './Pages/Legal/impressum';
import Datenschutz from './Pages/Legal/datenschutz';
import Konnichen from './Pages/Projects/konnichen';
import BiblePage from './Pages/Projects/bible-page';
import Socials from './Pages/about/socials';

function App() {
  const [colorMode, setColor] = useState('dark');
  const [language, setLanguage] = useState('de');
  const [openDropdown, setOpenDropdown] = useState(null);
  

  const dropdownRef = useRef(null);

  useEffect(() => {
    // Event Listener für Klicks
    const handleClickOutside = (event) => {
      // Wenn der Klick außerhalb des Dropdown-Containers ist, Dropdown schließen
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Listener entfernen, um Speicherlecks zu vermeiden
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (key) => {
    setOpenDropdown(openDropdown === key ? null : key);
  };

  const renderDropdown = (key, items) => (
    <li className='App-header-link-dropdown-item'>
      <button 
        className='App-header-link-heading' 
        onClick={() => toggleDropdown(key)}
        aria-expanded={openDropdown === key}
        aria-controls={`${key}-dropdown`}
      >
        {translations[language].header[key].header}
      </button>
      {openDropdown === key && (
        <ul id={`${key}-dropdown`} className='App-header-link-dropdown-dropdown'>
          {items.map((item, index) => (
            <li key={index}>
              <Link to={item.path}>{translations[language].header[key][item.translationKey]}</Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );


  const translations = {
    de: {
      header: {
        title: 'Konja Rehm',
        light: 'Wechsel zu Light-Mode',
        dark: 'Wechsel zu Dark-Mode',
        language: 'Deutsch',
        img: english, 
        projects: {
          header: 'Projekte',
          project1: "Konnichen",
          project2: 'Bibel-Website'
        },
        about: {
          header: 'Über mich',
          topic1: 'Hobbys',
          socials: 'Soziales',
          bio: 'Lebenslauf',
          blog: 'Blog'
        },
        playground: {
          header: 'Spielplatz',
          tic_tac_toe: "Tic Tac Toe",
          minesweeper: "Minesweeper",
          guessthenumber: "Errate die Nummer",
          rps: "Schere Stein Papier"
        },
        other: {
          header: 'Anderes',
          word_count: "Wörter zählen",
          wpm: "Wörter pro Minute"
        }
      
      },
      main: {
        header: 'Persönliche Links Übersicht',
        s: 'Teilen',
        c: 'Kopieren',
        m: 'Mehr',
        webp: {
          h3: 'Meine eigene Website'
        },
        konnichen: {
          h3: 'Discord Bot Support'
        },
        insta: {
          h3: 'Mein Instagram'
        },
        github: {
          h3: 'Mein Github'
        }
      },
      footer: {
        i: 'Impressum',
        d: 'Datenschutz'
      }
    },
    en: {
      header: {
        title: "Konja Rehm",
        light: 'Switch to light mode',
        dark: 'Switch to dark mode',
        language: 'English',
        img: german,
        projects: {
          header: 'Projects',
          project1: "Konnichen-Bot",
          project2: 'Bible-Webpage'
        },
        about: {
          header: 'About me',
          topic1: 'Hobbys',
          socials: 'Socials',
          bio: 'Curriculum Vitae',
          blog: 'Blog'
        },
        playground: {
          header: 'Playground',
          tic_tac_toe: "Tic Tac Toe",
          minesweeper: "Minesweeper",
          guessthenumber: "Guess the number",
          rps: "Rock Paper Scissors"
        },
        other: {
          header: 'Other',
          word_count: "Word count",
          wpm: "words per minute"
        }
      
      },
      main: {
        header: 'Personal links overview',
        s: 'Share',
        c: 'Copy',
        m: 'More',
        webp: {
          h3: 'My own webpage'
        },
        konnichen: {
          h3: 'Discord Bot Support'
        },
        insta: {
          h3: 'My Instagram'
        },
        github: {
          h3: 'My Github'
        }
      },
      footer: {
        i:'Imprint',
        d:'Privacy Policy'
      }
    }

  }; 



  const classes = {
    overall: `App ${colorMode}`,
    header: {
      overall: 'App-header',
      button: 'App-header-button'
    },
    main: {
      overall: 'App-main',
      ul: 'App-main-ul',
      li: 'App-main-ul-li',
      li_menu_button: 'menu-button'
    },
    footer: {
      button: 'App-footer-button'
    },
    menu: {
      overall: 'share-menu',
      box: 'share-menu-box',
      button: 'share-menu-button'
    }
  };




  const dropdowns = {
    projects: [
      { path: "/projects/konnichen", translationKey: "project1" },
      { path: "/projects/bible-page", translationKey: "project2" }
    ],
    about: [
      { path: "/about/socials", translationKey: "socials" },
      { path: "/about/hobbys", translationKey: "topic1" },
      { path: "/about/biographie", translationKey: "bio" },
      { path: "/about/blog", translationKey: "blog" }
    ],
    playground: [
      { path: "/playground/tic_tac_toe", translationKey: "tic_tac_toe" },
      { path: "/playground/minesweeper", translationKey: "minesweeper" },
      { path: "/playground/guessthenumber", translationKey: "guessthenumber" },
      { path: "/playground/rps", translationKey: "rps" }
    ],
    other: [
      { path: "/other/word_count", translationKey: "word_count" },
      { path: "/other/wpm", translationKey: "wpm" }
    ]
  };

  const toggleLang = () => {
    if (language === 'en') {
      setLanguage('de');
    } else {
      setLanguage('en');
    }
  };


  const toggleColor = () => {
    if (colorMode === 'light') {
      setColor('dark');
    } else {
      setColor('light');
    }
  };




  return (
    <div className={classes.overall}>
      <Router>
        <header className={classes.header.overall} ref={dropdownRef}>
          <h1 className="App-header-title"><Link to={"/"}>{translations[language].header.title}</Link></h1>
          <div className='App-header-links'>
            <ul className='App-header-link-dropdown'>
              {Object.entries(dropdowns).map(([key, items]) => renderDropdown(key, items))}
            </ul>
          </div>  

          <div className='App-header-buttons'>
            <button className={classes.header.button} onClick={toggleLang}>
              <img src={translations[language].header.img} alt="" className="DropdownImg" />
            </button>
            <button className={classes.header.button} onClick={toggleColor}>
              <h2 className='DropdownH2'>colorMode</h2>
            </button>
          </div>
        </header>

        <main className={classes.main.overall}>
          <Routes>
            <Route path='legal/imprint' element={<Impressum language={language} colorMode={colorMode} />} />
            <Route path='legal/privacy' element={<Datenschutz language={language} colorMode={colorMode} />} />
            <Route path='projects/konnichen' element={<Konnichen language={language} colorMode={colorMode} />} />
            <Route path='projects/bible-page' element={<BiblePage language={language} colorMode={colorMode} />} />
            <Route path='about/socials' element={<Socials language={language} colorMode={colorMode} />} />
          </Routes>
        </main>

        <footer className='App-footer'>
          <div className="App-footer-controls">
            <button className="legal-button">
              <Link to="/legal/imprint">{translations[language].footer.i}</Link>
            </button>
            <button className="legal-button">
              <Link to="/legal/privacy">{translations[language].footer.d}</Link>
            </button>
          </div>
        </footer>
      </Router>
    </div>
  );
}
export default App;
