import React from 'react';
import './css/impressum.css'

function Datenschutz ({language, colorMode}) {

    const translations = {
        de: {
            h1: 'Datenschutzerklärung',
            resp: 'Verantwortlicher',
            cap: 'Datenerfassung und -verarbeitung',
            capp: 'Unsere React-App erfasst und verarbeitet keine personenbezogenen Daten der Nutzer. Die App wird auf Vercel gehostet und funktioniert clientseitig ohne Verwendung externer Dienste oder Tracking-Tools.',
            hosting: 'Unsere Website wird auf Vercel gehostet. Vercel kann automatisch bestimmte Zugriffsdaten wie IP-Adressen in Server-Logs speichern. Diese Daten werden ausschließlich für technische und statistische Zwecke verwendet und nach maximal 7 Tagen gelöscht.',
            cookies: 'Diese App verwendet keine Cookies.',
            rights: 'Ihre Rechte',
            rightsp: 'Sie haben das Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der Verarbeitung Ihrer personenbezogenen Daten gemäß den geltenden Datenschutzgesetzen.',
            and: 'Änderungen der Datenschutzerklärung',
            andp: 'Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf zu aktualisieren. Bitte überprüfen Sie diese Seite regelmäßig auf Änderungen. Letzte Aktualisierung: 26.11.2024'
        },
        en: {
            h1: 'Privacy Policy',
            resp: 'Responsible Party',
            cap: 'Data Collection and Processing',
            capp: 'Our React app does not collect or process any personal data from users. The app is hosted on Vercel and operates client-side without the use of external services or tracking tools.',
            hosting: 'Our website is hosted on Vercel. Vercel may automatically store certain access data such as IP addresses in server logs. This data is used solely for technical and statistical purposes and is deleted after a maximum of 7 days.',
            cookies: 'This app does not use cookies.',
            rights: 'Your Rights',
            rightsp: 'You have the right to access, rectify, delete, and restrict the processing of your personal data in accordance with applicable data protection laws.',
            and: 'Changes to the Privacy Policy',
            andp: 'We reserve the right to update this privacy policy as needed. Please check this page regularly for changes. Last updated: 26.11.2024'
        }
    }
    return (
    <div className='pp'>
        <h1>{translations[language].h1}</h1>
        <br></br>
        <h2>{translations[language].resp}</h2>
        <p>Konja Rehm<br/>Kirchstraße 4<br/>02739 Kottmar Germany</p>
        <br></br>
        <h2>{translations[language].cap}</h2>
        <p>{translations[language].capp}</p>
        <br></br>
        <h2>Hosting</h2>
        <p>{translations[language].hosting}</p>
        <br></br>
        <h2>Cookies</h2>
        <p>{translations[language].cookies}</p>
        <br></br>
        <h2>{translations[language].rights}</h2>
        <p>{translations[language].rightsp}</p>
        <br></br>
        <h2>{translations[language].and}</h2>
        <p>{translations[language].andp}</p>
    </div>
    );
};
export default Datenschutz;
